// https://github.com/willmcpo/body-scroll-lock/issues/182#issuecomment-658631202
// https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open/
export class ScrollLockService {
    public static preLockScroll = 0;
    public static bodyScrollLocked = false;

    public static lockBodyScroll = () => {
        if (ScrollLockService.bodyScrollLocked) {
            return;
        }
        ScrollLockService.bodyScrollLocked = true;
        document.querySelector("html").style.scrollBehavior = "unset";
        const tmpScrollY = window.scrollY;
        ScrollLockService.preLockScroll = window.scrollY;
        document.body.style.position = "fixed";
        document.body.style.top = `-${tmpScrollY}px`;
    };

    public static unlockBodyScroll = () => {
        if (!ScrollLockService.bodyScrollLocked) {
            return;
        }
        ScrollLockService.bodyScrollLocked = false;
        document.body.style.position = "";
        document.body.style.top = "";
        window.scrollTo(0, this.preLockScroll);
        this.preLockScroll = 0;
        document.querySelector("html").style.scrollBehavior = "smooth";
    };
}
