import { Timeout } from "../decorators/timeout.decorator";
import { DataAccess } from "../dataaccess/data.access";
import { S25Util } from "../util/s25-util";
import { Cache, Invalidate } from "../decorators/cache.decorator";
import { Rules } from "../modules/s25-rule-tree/s25.rule.const";
import { Proto } from "../pojo/Proto";
import NumericalBoolean = Proto.NumericalBoolean;
import { CustomAttributeService } from "./custom.attribute.service";
import { S25ItemI } from "../pojo/S25ItemI";
import Condition = Rules.Condition;
import { EventSummary } from "../modules/s25-swarm-schedule/s25.event.summary.service";
import DowChar = EventSummary.DowChar;

export interface RuleTargetItem {
    itemId: number;
    itemName: string;
    itemTypeId: Rules.TargetId;
    itemValue?: string;
    sort_order?: number; // Only for custom attributes?
    custAtrbType?: Rules.AttributeType; // Only for itemTypeId === CustomAttribute (11)
    value?: string;
    action?: Rules.Action;
}

export interface RuleValue {
    value: string;
    itemName?: string; // Not present if operator is "in"
    itemTypeId?: number;
    itemId?: number;
}

export interface RuleSource {
    itemName: string;
    itemTypeId: Rules.TypeId;
    itemId?: number; // Only with custom attributes?
    custAtrbType?: Rules.AttributeType; // Only for itemTypeId === CustomAttribute (11)
}

export interface RuleItem {
    operator: Rules.Operator;
    type: "comp";
    sourceItem: RuleSource;
    val_obj: RuleValue[];
    children: "";
}

export interface RuleItems {
    operator: Rules.Conjunction;
    type: "bool";
    children: {
        item: (RuleItems | RuleItem)[];
    };
}

export interface Rule {
    rule_name: string;
    rule_cat: string;
    sub_cat?: string;
    root_rule_id: number;
    active: NumericalBoolean;
    description: string;
    item: RuleItems & {
        targetAction?: Rules.Action; // NOTE: Is set as "target_action" but returned as "targetAction"
        targetItem?: RuleTargetItem[];
        actions: RuleAction[];
    };
}

export type RuleAction = {
    action: Rules.Action;
    targets: RuleActionTarget[];
};

export type RuleActionTarget = {
    itemTypeId: number;
    itemId: number;
    itemName: string;
    itemValue: string;
    custAtrbType?: Rules.AttributeType; // Only for custom attributes (itemTypeId = 11)
};

export interface RuleTableItem {
    dummy_id: number; // Dummy ids determine sort order!
    parent_dummy_id?: number; // Only if it has a parent
    operator: Rules.Conjunction | Rules.Operator;
    rule_type: "bool" | "comp";
    value?: any; // Can be put on RuleValueList instead
    source_item_id?: number; // Only with Custom Attributes
    source_item_type_id?: Rules.TypeId; // CustomAttribute (11) or Object (2, 4, 6, 19, 99)
    // Only for first item!
    rule_cat?: string;
    sub_cat?: string;
    rule_name?: string | number;
    description?: string | number;
    target_action?: Rules.Action;
    target_item_id?: number; // Can be put in RuleTargetList instead
    target_item_type_id?: Rules.TargetId;
    target_item_value?: string; // Only Alert/Notify user
}

export interface RuleValueListItem {
    dummy_id: number;
    value: any;
}

export interface RuleActionListItem {
    dummy_id: number;
    action: Rules.Action;
    targets: RuleActionListTarget[];
}

export type RuleActionListTarget = {
    target_type_id: number;
    target_id: number;
    value: string;
};

export class RuleTreeService {
    @Timeout
    @Cache({ targetName: "RuleTreeService", immutable: true })
    public static async getRules(
        category: Rules.Category = "form",
        expanded: boolean = false,
        subCategory?: string | number,
    ): Promise<Rule[]> {
        subCategory = S25Util.toStr(subCategory);
        let url = `/rule/trees.json?category_id=${category}`;
        if (expanded) url += "&expanded=T";
        const rules = (await DataAccess.get<{ rule: Rule[] }>(url))?.rule || [];

        for (let rule of rules.filter((r: Rule) => r.item?.actions && (!subCategory || r.sub_cat === subCategory))) {
            if (S25Util.isDefined(rule.sub_cat)) rule.sub_cat = S25Util.toStr(rule.sub_cat);
            for (let { targets } of rule.item.actions) {
                for (let item of targets || []) {
                    if (S25Util.isDefined(item.itemValue)) item.itemValue = String(item.itemValue); // Service returns numerical strings as numbers
                }
            }
        }

        return rules;
    }

    @Timeout
    @Invalidate({ serviceName: "RuleTreeService", methodName: "getRules" })
    public static putRule(
        id: string | number,
        active: boolean,
        ruleTable: RuleTableItem[],
        valueList: RuleValueListItem[],
        actionList: RuleActionListItem[],
    ) {
        const payload = {
            root: {
                active: +active,
                rule: S25Util.deleteUndefDeep(ruleTable),
                valueList: S25Util.deleteUndefDeep(valueList),
                actions: S25Util.deleteUndefDeep(actionList),
            },
        };
        return DataAccess.put(`/rule/tree.json?itemId=${id}`, payload);
    }

    public static async putParsedRule(rule: Rules.Rule) {
        let { rules, values } = await this.getArrayRepresentation(rule);
        return this.putRule(rule.id, rule.active, rules, values, this.getActions(rule));
    }

    public static async getArrayRepresentation(rule: Rules.Rule) {
        let resp = await this.getArrayRepresentationOfCondition(null, rule.id, rule.conditions);
        resp.rules[0].rule_cat = rule.category;
        resp.rules[0].rule_name = rule.name;
        resp.rules[0].description = rule.description;
        resp.rules[0].sub_cat = rule.subCategory;
        return resp;
    }

    public static async getArrayRepresentationOfCondition(
        parentId: number,
        id: number,
        condition: Rules.Conditions | Rules.Condition,
    ): Promise<{ rules: RuleTableItem[]; values: RuleValueListItem[] }> {
        let resp: { rules: RuleTableItem[]; values: RuleValueListItem[] } = { rules: [], values: [] };
        let branch = condition as Rules.Conditions;
        let leaf = condition as Rules.Condition;
        if (branch.children) {
            resp.rules.push({
                parent_dummy_id: parentId,
                dummy_id: id,
                operator: branch.operator,
                rule_type: "bool",
            });
            let nextId = id + 1;
            for (const child of branch.children) {
                let arrayRep = await this.getArrayRepresentationOfCondition(id, nextId, child);
                [].push.apply(resp.rules, arrayRep.rules);
                [].push.apply(resp.values, arrayRep.values);
                nextId += arrayRep.rules.length;
            }
        } else {
            resp.rules.push({
                dummy_id: id,
                parent_dummy_id: parentId,
                operator: leaf.operator,
                rule_type: "comp",
                source_item_type_id: leaf.type,
                source_item_id: leaf.sourceItem?.itemId,
            });
            let valueType = await this.getValueType(leaf);
            [].push.apply(resp.values, this.getValues(valueType.type, leaf.operator, id, leaf.values));
        }
        return resp;
    }

    public static async getValueType(condition: Condition): Promise<Rules.ValueType> {
        const options = await CustomAttributeService.getAllDiscreteOptions();
        let discreteOptions: Record<number, S25ItemI[]> = {};
        for (let option of options)
            discreteOptions[option.itemId] = option.opt.map((opt: string) => ({ itemId: opt, itemName: opt }));
        let type = Rules.typeIdToType[condition.type];
        if (type === Rules.type.CustomAttribute && condition.sourceItem) {
            if (
                condition.sourceItem.attributeType === Rules.attributeType.Text &&
                discreteOptions[condition.sourceItem.itemId]
            ) {
                return Rules.valueType.Discrete;
            } else {
                return Rules.attributeValueType[
                    condition.sourceItem.attributeType as keyof typeof Rules.attributeValueType
                ];
            }
        } else {
            return type.valueType;
        }
    }

    public static getValues(valueType: string, operator: Rules.Operator, id: number, values: Rules.ConditionValue[]) {
        if (Rules.valuelessOperators.has(operator)) return [];
        return values.map((value) => {
            switch (valueType) {
                case "matchForm":
                case "matchQuestion":
                    return { dummy_id: id, value: value as string };
                case "multiselect":
                case "multiselects": {
                    const { itemName, itemId, itemTypeId } = value as RuleValue;
                    const data = { itemName, itemId, itemTypeId, value: String((value as RuleValue).value) };
                    return { ...S25Util.camelToSnakeObj(data), dummy_id: id };
                }
                case "search":
                case "discrete":
                    return { dummy_id: id, value: (value as Rules.ItemValue).itemId };
                case "date":
                    return { dummy_id: id, value: S25Util.date.toS25ISODateStr(value) };
                case "time":
                    return { dummy_id: id, value: S25Util.date.toS25ISOTimeStr(value) };
                case "datetime":
                    return { dummy_id: id, value: S25Util.date.toS25ISODateTimeStr(value) };
                case "occurrenceDate":
                    return {
                        dummy_id: id,
                        value: (value as Date[])
                            .filter((_) => _)
                            .map((date) => S25Util.date.toS25ISODateTimeStr(date))
                            .sort()
                            .join(),
                    };
                case "occurrenceTime":
                    return {
                        dummy_id: id,
                        value: (value as Date[])
                            .filter((_) => _)
                            .map((date) => S25Util.date.dateToHours(date))
                            .sort((a, b) => a - b)
                            .join(),
                    };
                case "occurrenceDow":
                    return {
                        dummy_id: id,
                        value: Object.entries(value as Record<DowChar, boolean>)
                            .filter(([_, checked]) => checked)
                            .map(([dow]) => dow)
                            .join(""),
                    };
                case "relativeDate":
                    const relativeDateValue = value as { days: number; type: string };
                    return { dummy_id: id, value: `${relativeDateValue.type},${relativeDateValue.days}` };
                default:
                    return { dummy_id: id, value };
            }
        });
    }

    public static getActions(rule: Rules.Rule) {
        const actions: RuleActionListItem[] = Object.entries(rule.targets).map(([action, items]) => {
            const target = Rules.targetActionToTarget[action as Rules.Action];

            let targets = items.map((item) => ({
                target_type_id: target === Rules.target.Attributes ? target.id : item.itemTypeId || target.id,
                target_id: item.itemId,
                value: item.itemValue,
            }));

            // Filter out empty textareas
            if (target?.valueType.type === "textarea" || target?.valueType.type === "richText") {
                targets = targets.filter((target) => target.value);
            }

            // Filter out false "yesNo"
            if (target?.valueType.type === "yesNo") {
                targets = targets.filter((target) => S25Util.toBool(target.value));
            }

            return { dummy_id: rule.id, action: action as Rules.Action, targets };
        });

        rule.targets?.addContactRole &&
            actions.push({
                dummy_id: rule.id,
                action: "populateContactRole",
                targets: rule.targets.addContactRole
                    .filter((role) => role.contact)
                    .map((role) => ({
                        target_type_id: 3,
                        target_id: role.contact.itemId,
                        value: `contact_role_id=${role.itemId}`,
                    })),
            });

        return actions;
    }

    @Timeout
    @Invalidate({ serviceName: "RuleTreeService", methodName: "getRules" })
    public static delRule(id: string | number): Promise<{}> {
        return DataAccess.delete(`/rule/tree.json?itemId=${id}`);
    }
}
